/* LoadingSpinner.css */

.loadings-spinner {
  background-color: rgba(44, 37, 67, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Adjust color as needed */
    width: 50px; /* Default size */
    height: 50px; /* Default size */
    animation: spin 1s linear infinite;
  }
  
  .spinners-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  