.help-container {
    position: relative;
    display: inline-block;
  }
  
  .help-trigger {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .help-message {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px; /* Adjust as needed */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .arrow-up {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ccc;
    top: -10px;
    left: calc(50% - 10px);
  }
  
  .help-text {
    font-size: 14px;
    line-height: 1.4;
    color: #333;
  }
  